<template>
  <div class="col-md-3 col-lg-2 header-navbar-outer">
    <div class="w-100">
      <div class="navbar-brand-1 me-0">
        <div class="d-flex justify-content-between align-items-center">
          <!-- <div class="logo-outer">
            <img src="/assets/images/smart-force-logo.png" height="72"  alt="" />
          </div> -->
          <div class="header-police-logo-outer">
            <div class="header-police-logo-inner w-100">
              <img
                src="/assets/images/goa-police-logo.png"
                class="header-goa-logo"
                alt="goa police"
                v-if="this.domain_name != 'assam.smartforce.in'"
              />
              <img
                src="/assets/images/assam-police-logo.png"
                class="header-goa-logo"
                alt="goa police"
                v-if="this.domain_name == 'assam.smartforce.in'"
              />
              <div class="header-goa-label text-uppercase">
                {{
                  this.domain_name == "assam.smartforce.in"
                    ? "assam police"
                    : "goa police"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav id="sidebarMenu" class="d-md-block collapse nav-navlink-outer">
        <div class="menubar-outer">
          <div
            class="menubar-submeanu-outer"
            v-for="(menuItem, key) in sidebarMenu"
            :key="key"
          >
            <ul class="nav flex-column" :id="'header-' + key">
              <li class="nav-item" v-if="menuItem.mbj8 == 3 || menuItem.mbj8 == 2">
                <router-link
                  v-if="menuItem.children.length == 0"
                  class="nav-link"
                  :to="'/' + menuItem.mbj9 || ''"
                >
                  <img
                    v-if="menuItem.mbj10"
                    :src="'/assets/images/new-icon/' + menuItem.mbj10"
                    class="navlink-tab-icon"
                    alt="menu"
                    width="16"
                  />
                  <img
                    v-else
                    src="/assets/images/new-icon/menu-dashboard.svg"
                    width="16"
                  />
                  {{ menuItem.label }}</router-link
                >
                <button
                  v-else-if="menuItem.children.length > 0"
                  class="btn btn-toggle align-items-center rounded collapsed"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#${menuItem.label
                    .replaceAll(' ', '-')
                    .replaceAll('&', '-')}`"
                  aria-expanded="false"
                >
                  <img
                    v-if="menuItem.mbj10"
                    :src="'/assets/images/new-icon/' + menuItem.mbj10"
                    class="navlink-tab-icon"
                    alt="submenu"
                    width="16"
                  />
                  <img
                    v-else
                    src="/assets/images/new-icon/menu-dashboard.svg"
                    class="navlink-tab-icon"
                    width="16"
                  />{{ menuItem.label }}
                </button>
                <div
                  v-if="menuItem.children"
                  class="collapse"
                  data-bs-parent="#sidebarMenu"
                  :id="menuItem.label.replaceAll(' ', '-').replaceAll('&', '-')"
                >
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li v-for="(subMenu, index) in menuItem.children" :key="index">
                      <router-link class="nav-link" :to="'/' + subMenu.mbj9"
                        ><div class="submenu-active-span">
                          {{ subMenu.label }}
                        </div></router-link
                      >
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="copyright-outer mt-auto">
        <div class="copyright-inner" v-if="this.domain_name != 'assam.smartforce.in'">
          <div class="pb-2">
            <img
              class="img-fluid-goa-sidebar w-100"
              alt=""
              src="/assets/images/white-GOA-electronics-01-01.png"
            />
          </div>
          <div>
            <img src="/assets/images/login-samrudhh-logo.png" height="50" alt="" />
          </div>
        </div>
        <div class="copyright-inner" v-if="this.domain_name == 'assam.smartforce.in'">
          <div class="pb-2">
            <img
              class="img-fluid-goa-sidebar w-100"
              alt=""
              src="/assets/images/assam-map.png"
            />
          </div>

          <div>
            <img src="/assets/images/login-samrudhh-logo.png" height="50" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "../service/ApiService";
export default {
  data() {
    return {
      sidebarMenu: [],
      domain_name: "",
    };
  },
  mounted() {
    this.domain_name = this.$store.state.loggedInUserSessions.client_info.maa25;
    this.getLawUserModules();
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  methods: {
    getLawUserModules() {
      this.ApiService.getModules().then((data) => {
        if (data.status == 200) {
          this.sidebarMenu = data.data;
        } else {
          this.sidebarMenu = [];
        }
      });
    },
  },
};
</script>
